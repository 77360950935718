import { Component, Mixins, Emit } from 'vue-property-decorator';
import ViewPage from '@/mixins/Views';
import VueRouter from 'vue-router';
// @ is an alias to /src
import store from '@/store';
import $ from 'jquery';
import 'slick-carousel';
import 'slick-carousel/slick/slick.css'; // 追加
import 'slick-carousel/slick/slick-theme.css'; // 追加

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
  'beforeDestroy',
]);

@Component
export default class Pages extends Mixins(ViewPage) {
  private name = 'pages';
  private slug: string|null = null;
  get entryData(): HTMLElement {
    return this.$store.getters['pages/data'][0].content;
  }
  get loadedPost(): boolean {
    return store.getters['pages/loaded'];
  }
  get statusCode(): number {
    return store.getters['pages/statusCode'];
  }
  get lang(): string {
    return this.$store.getters['auth/lang'];
  }
  public mounted() {
    store.commit('loaded', true);
    this.slug = String(this.$route.params.slug);
    const router = this.$router;
    $(document).on('click', '#pages-container a', function(e) {
      const moveTo: any = $(this).attr('href');
      const target: any = $(this).attr('target');
      const test1 = /^http(s)?:\/\//.test(moveTo);
      const test2 = moveTo.indexOf(process.env.VUE_APP_SITE_URL);
      if ((!test1 || test2 === 0) && target !== '_blank') {
        e.preventDefault();
        router.push(String(moveTo));
      }
    });

    this.setSlick();
  }
  private async beforeRouteEnter(to: any, from: VueRouter, next: any) {
    const result = await store.dispatch('pages/getEntry', {
      slug: to.params.slug,
    });
    if (result.status === 200 && result.data.datas.length === 0) {
      store.commit('pages/setStatusCode', 404);
      store.dispatch('head/updateTitle', '404 Page not found', {root: true});
    } else {
      store.commit('pages/setStatusCode', result.status);
    }
    if (result.status === 200 && result.data.datas.length > 0) {
      store.commit('pages/setData', result.data.datas);
      store.dispatch('head/updateTitle', result.data.datas[0].title, {root: true});
    }
    next();
  }
  private async beforeRouteUpdate(to: any, from: VueRouter, next: any) {
    const result = await store.dispatch('pages/getEntry', {
      slug: to.params.slug,
    });
    this.slug = String(this.$route.params.slug);
    if (result.status === 200 && result.data.datas.length === 0) {
      store.commit('pages/setStatusCode', 404);
      store.dispatch('head/updateTitle', '404 Page not found', {root: true});
    } else {
      store.commit('pages/setStatusCode', result.status);
    }
    if (result.status === 200 && result.data.datas.length > 0) {
      store.commit('pages/setData', result.data.datas);
      store.dispatch('head/updateTitle', result.data.datas[0].title, {root: true});
    }
    next(); // needs to be called to confirm the navigation
  }
  private updated() {
    this.slug = String(this.$route.params.slug);
    this.setSlick();
  }
  private destroyed() {
    $(document).off('click', '#pages-container a');
  }
  @Emit()
  private setSlick() {
    const slickElm: any = $('.slick-carousel');
    slickElm.slick({
      responsive: [
        {
          breakpoint: 9999,
          settings: 'unslick'
        },
        {
          breakpoint: 750,
          settings: {
            dots: true,
            infinite: false,
            arrows: false,
            variableWidth: true,
          }
        }
      ]
    });
  }

}
