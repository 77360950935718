
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueRouter, { Route } from 'vue-router';
import axios, {AxiosResponse, AxiosError} from 'axios';
import Singlepages from '@/mixins/SinglepagesJa';
import PageHeader from '@/components/page/PageHeader.vue';
import BoxSnsItem from '@/components/box/BoxSnsItem/BoxSnsItem.vue';
import store from '@/store';

Component.registerHooks([
  'beforeRouteEnter',
]);

@Component({
  components: {
    PageHeader,
    BoxSnsItem
  }
})
export default class BrandJa extends Mixins(Singlepages) {
  private list: SNS[] = [];

  private async beforeRouteEnter(to: Route, from: Route, next: any) {
    const api = `/api/wp-json/31corp/v1/sns?lang=${store.getters['auth/lang']}`;
    await axios({
      method: 'get',
      url: api,
      headers: { Authorization: 'Bearer ' + store.getters['auth/token']},
    })
    .then( (response: AxiosResponse<SNS[]>) => {
      store.dispatch('head/updateTitle', 'ホテル紹介');
      next((vm: {list: SNS[]}) => {
        vm.list = response.data;
      });
    })
    .catch( (e: AxiosError) => {
      console.log(e);
      next((vm: {list: SNS[]}) => {
        vm.list = [];
      });
    });
  }

}
